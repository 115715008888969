import Slider from './slider.js';

let uiWrapper = null;

let constructorCb = null;

let followElements = [];
let followElementsPositions = [];

let videoElementSource = '';
let slider = null;
let popup = null;

export default class UI {
  constructor(callback, wrapper) {
    constructorCb = callback;
    uiWrapper = wrapper;

    followElements = uiWrapper.querySelectorAll('[data-follow]');
    this.getFollowElementsPosition();

    const events = [
      {selector: '.burger', cb: this.toggleMenu},
      {
        selector: '.fixed-content-header__contact',
        cb: this.onPagingClick.bind(this),
      },
      {
        selector: '.fixed-content-paging',
        cb: this.onPagingClick.bind(this),
      },
      {selector: '.menu-list', cb: this.onMenuPagingClick.bind(this)},
      {selector: '#button_services', cb: this.onPagingClick.bind(this)},
      {
        selector: '#button_cookies_consent',
        cb: this.acceptCookieConsent.bind(this),
      },
      {
        selector: '#button_offers',
        cb: this.showPopup.bind(this, 'offers'),
      },
      {
        selector: '#button_cookie_policy',
        cb: this.showPopup.bind(this, 'cookiePolicy'),
      },
      {
        selector: '#button_cookie_policy_footer',
        cb: this.showPopup.bind(this, 'cookiePolicy'),
      },
      {
        selector: '#button_portfolio1',
        cb: this.showPopup.bind(this, 'portfolio1'),
      },
      {
        selector: '#button_portfolio2',
        cb: this.showPopup.bind(this, 'portfolio2'),
      },
      {
        selector: '#button_team',
        cb: this.showPopup.bind(this, 'team', this.initSlider,
            this.destroySlider),
      },
    ];
    events.forEach(event => {
      const element = uiWrapper.querySelector(event.selector);
      if (element) {
        element.addEventListener('click', event.cb);
      }
    });

    const footerYear = uiWrapper.querySelector('.footer-copy__date');
    if (footerYear) {
      footerYear.innerHTML = `© ${new Date().getFullYear()}`;
    }

    let cookie_consent = this.getCookie('user_cookie_consent');
    if (cookie_consent !== '') {
      document.getElementById('cookieNotice').style.display = 'none';
    } else {
      // this.showPopup('cookieNotice');
      document.getElementById('cookieNotice').style.display = 'block';
    }

  }

  ui_moveEvent(e, Use2DTextOver3D) {
    this.buttonMoveAnimation(e);
    if (Use2DTextOver3D) {
      this.mainLetters2DAnimation(e);
    }
  }

  buttonMoveAnimation(e) {
    const mouseLeft = e.clientX;
    const mouseTop = e.clientY;
    let elementAlreadyCentered = {y: false};
    followElements.forEach((element, index) => {
      const elementPositions = followElementsPositions[index];
      // If cursor is close to the button element then move the button closer to the cursor;
      if (mouseLeft > elementPositions.left - 100 && mouseLeft <
          elementPositions.right + 100 &&
          mouseTop > elementPositions.top - 100 && mouseTop <
          elementPositions.bottom + 100) {
        const moveX = (elementPositions.left - mouseLeft) / 10;
        const moveY = (elementPositions.top - mouseTop) / 10;

        // If moved element already has some kind of centering set, add that position to calc;
        elementAlreadyCentered.y = false;
        if (element.dataset.follow === 'centered_y') {
          elementAlreadyCentered.y = true;
        }

        element.style.transform = `translate3d(${-moveX}px, calc(${elementAlreadyCentered.y ?
            -50 :
            0}% + ${-moveY}px), 0)`;
        element.style.transition = '';
      } else {
        element.style.transform = ``;
        element.style.transition = 'transform 500ms ease';
      }
    });
  }

  mainLetters2DAnimation(e) {
    const letters = document.querySelector('.configuration__letters');

    if (letters) {
      const xCenter = window.innerWidth / 2;
      const yCenter = window.innerHeight / 2;
      const LettersXPosition = xCenter - e.clientX;
      const LettersYPosition = yCenter - e.clientY;
      letters.style.transform = `rotateX(${-LettersXPosition /
      50}deg) rotateY(${LettersYPosition / 50}deg) translateX(-50%)`;
    }
  }

  showPopup(popupType, createCallback, destroyCallback) {
    if (typeof createCallback === 'function') createCallback();
    popup = uiWrapper.querySelector(`[data-popup=${popupType}]`);
    popup.classList.add('popup--active');
    // First remove display: none, then add animated class;
    setTimeout(() => {
      popup.classList.add('popup--animated');
      popup.addEventListener('click', this.hidePopup);
      // Add parameter to prototype, so listener can be removed;
      popup._eventParameter = destroyCallback;
      constructorCb().blockSceneScrolling(true);
      this.getFollowElementsPosition();
    }, 0);
  }

  hidePopup(event) {
    if (event.target !== event.currentTarget) return;
    popup.classList.remove('popup--active');
    popup.classList.remove('popup--animated');
    popup.removeEventListener('click', this.hidePopup);
    constructorCb().blockSceneScrolling(false);
    if (typeof popup._eventParameter === 'function') popup._eventParameter();
  }

  initSlider() {
    const sliderElement = uiWrapper.querySelector('#slider');
    const leftArrow = uiWrapper.querySelector('.slider__arrow-left');
    const rightArrow = uiWrapper.querySelector('.slider__arrow-right');
    slider = new Slider(sliderElement, leftArrow, rightArrow);
  }

  destroySlider() {
    slider.destroy();
    slider = null;
  }

  initVideo() {
    if (!videoElementSource) return;
    const videoElement = document.querySelector('#video');
    if (videoElement) {
      videoElement.setAttribute('src', videoElementSource);
    }
  }

  destoryVideo() {
    const videoElement = document.querySelector('#video');
    if (videoElement) {
      videoElementSource = videoElement.src;
      // Remove src tag, because you can't stop video while in iFrame;
      videoElement.removeAttribute('src');
    }
  }

  toggleMenu() {
    uiWrapper.classList.toggle('menu-opened');
    uiWrapper.querySelector('.burger').classList.toggle('burger--active');
  }

  ui_moveScene(direction) {
    this.checkContentVisibility(direction);
  }

  onMenuPagingClick(e) {
    this.toggleMenu();
    this.onPagingClick(e);
  }

  onPagingClick(e, page = null) {
    const datasetPage = page ? page : +e.target.dataset.page;
    if (datasetPage >= 0) {
      constructorCb().onPagingClick(datasetPage);
    }
  }

  getFollowElementsPosition() {
    followElementsPositions = [];
    followElements.forEach(element => followElementsPositions.push(
        element.getBoundingClientRect()));
  }

  checkContentVisibility(direction) {
    const contentSections = uiWrapper.querySelectorAll('[data-page]');
    const animateSection = section => {
      // Add different class depending on scroll direction.
      if (direction === 'down') {
        section.classList.add('section--hidden');
        section.classList.remove('section--hidden-reverse');
      } else {
        section.classList.add('section--hidden-reverse');
        section.classList.remove('section--hidden');
      }

      if (+section.dataset.page === constructorCb().getCurrentPage()) {
        section.style.display = 'flex';
        const removeClass = () => section.classList.remove(
            'section--hidden', 'section--hidden-reverse');
        window.requestAnimationFrame(() => {
          window.requestAnimationFrame(removeClass);
        });
      }
    };

    // For each section first hide them all then show active.
    contentSections.forEach(section => {
      // Set low opacity on section that is leaving.
      // Transition is set in (main.css).
      section.style.opacity = '0';
      // Set timeout for the transition to end.
      setTimeout(() => {
        // Remove style tag with opacity and display property.
        section.removeAttribute('style');
        animateSection(section);
      }, 300);
    });
  }

  // Create cookie
  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  // Delete cookie
  deleteCookie(cname) {
    const d = new Date();
    d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=;' + expires + ';path=/';
  }

  // Read cookie
  getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  // Set cookie consent
  acceptCookieConsent() {
    this.deleteCookie('user_cookie_consent');
    this.setCookie('user_cookie_consent', 1, 30);
    document.getElementById('cookieNotice').style.display = 'none';
  }
}
