<template>
  <div>
    <p class="content-section__text">
      We use cookies to help improve your experience on our website. The data stored by the cookie files used
      on our application never show personal details on the basis of which an individual identity can be
      established.
    </p>

    <p class="content-section__text">
      If you don’t wish to accept cookies from us, you should instruct your browser to refuse cookies from
      https://icdavid.tech, with the understanding that we may be unable to provide you with some of your
      desired content and services.
      This policy explains how we use cookies and what options are available to you.
    </p>

    <h3 class="popup__title text-xl italic mb-2.5">
      What a cookie is and what it is used for
    </h3>

    <p class="content-section__text">
      Cookies are small text packets used to store information in web browsers. Cookies are used to store and
      receive identifiers and other information on computers, phones and other devices. Cookies make your
      interaction with the website safer and faster because they can remember your preferences (e.g. login,
      language), sending the information they contain back to the originating site (first-party cookie) or to
      another site to which they belong (third-party cookie) when you visit the website again, i.e. using the
      same end device.
    </p>

    <p class="content-section__text">
      Cookies consist of two parts: the name and the content or value of the cookie file.
    </p>

    <h3 class="popup__title text-xl italic mb-2.5">
      Types of cookies used
    </h3>

    <p class="content-section__text">
      Essential cookies are crucial to your experience of a website, enabling core features like user logins,
      account management, shopping carts and payment processing. We use essential cookies to enable certain
      functions on our website.

      <br>
      <br>

      Performance cookies are used in the tracking of how you use a website during your visit, without
      collecting personal information about you. Typically, this information is anonymous and aggregated with
      information tracked across all site users, to help companies understand visitor usage patterns, identify
      and diagnose problems or errors their users may encounter, and make better strategic decisions in
      improving their audience’s overall website experience. These cookies may be set by the website you’re
      visiting (first-party) or by third-party services. We use performance cookies on our website.

      <br>
      <br>

      Functionality cookies are used in collecting information about your device and any settings you may
      configure on the website you’re visiting (like language and time zone settings). With this information,
      websites can provide you with customized, enhanced or optimized content and services. These cookies may
      be set by the website you’re visiting (first-party) or by third-party service. We use functionality
      cookies for selected features on our website.

      <br>
      <br>

      This category also includes "third party" cookies, cookies placed by third parties on a site - for
      example, analytics providers and content partners. We grant these third parties access to selected
      information to perform specific tasks on our behalf. They may also set third-party cookies in order to
      deliver the services they are providing. Third-party cookies can be used to track you on other websites
      that use the same third-party service. As we have no control over third-party cookies, they are not
      covered by our cookie policy.
    </p>

    <h3 class="popup__title text-xl italic mb-2.5">
      Where do we use cookies
    </h3>

    <p class="content-section__text">
      We may place cookies on your computer or device and receive the information stored in these cookies when
      you use or visit the website.
    </p>

    <h3 class="popup__title text-xl italic mb-2.5">
      What are the benefits of cookies
    </h3>

    <p class="content-section__text">
      Cookies help to provide better web services and result in a friendly and personalized browsing
      experience for each user. Refusing or disabling cookies can make some websites difficult or even
      impossible to use.
    </p>


    <h3 class="popup__title text-xl italic mb-2.5">
      Security and privacy
    </h3>

    <p class="content-section__text">
      Cookies are NOT viruses! They are not made up of pieces of code, so they cannot be executed, nor can
      they run themselves. Consequently, they cannot be duplicated or replicated on other networks to run or
      replicate themselves again. In general, browsers have privacy settings that provide different levels of
      cookie acceptance, validity period and automatic deletion after the user has visited a website.
      What options do you have regarding cookies

      <br>
      <br>

      If you do not wish to accept cookies from us, you can instruct your browser to refuse cookies from our
      website. Most browsers are configured to accept cookies by default, but you can update these settings to
      either refuse cookies altogether, or to notify you when a website is trying to set or update a cookie.

      <br>
      <br>

      If you want to remove cookies stored on your devices so that they refuse cookies, you can use the
      preferences settings on the device you are using. Typically, you can find the navigation settings
      related to cookie files in the "Options", "Tools" or "Preferences" menus of the web browser or device
      you use to access the website. Depending on your existing web browser, various means can be used to
      disable cookies.

      <br>
      <br>

      For more information about cookies, please visit www.allaboutcookies.org or see
      www.youronlinechoices.eu, which contains additional information about behavioral advertising and online
      privacy.

      <br>
      <br>

      Important to note - most browsers automatically accept cookies, so if you do not want to use cookies,
      you will need to take action to delete or block them. Go to the "Help" option of the browser you are
      using for more details.
    </p>

  </div>
</template>

<script>
export default {
  name: 'CookiePolicy',
};
</script>

<style scoped>

</style>
