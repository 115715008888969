<template>
  <main class="ui-wrapper page-not-loaded">
    <!-- LOADER -->
    <div class="loader flex flex--center">
      <span class="loader__text">ICDavid Technologies</span>
      <!--        <img loading="lazy" class="logo" src="./assets/images/logo/Original-transparent.png" alt="Logo ICDavid" />-->
    </div>

    <!--      <div class="fixed-header__logo">-->
    <!--          <img loading="lazy" class="logo" src="./assets/images/logo/Original-transparent.png" alt="Logo ICDavid"  data-page="0" />-->
    <!--      </div>-->

    <!-- MAIN MENU -->
    <div class="menu">
      <ul class="menu-list">
        <li class="menu-list__item" data-page="1">ABOUT US</li>
        <li class="menu-list__item" data-page="2">SERVICES</li>
        <li class="menu-list__item" data-page="3">OUR VALUES</li>
        <li class="menu-list__item" data-page="4">TEAM</li>
        <li class="menu-list__item" data-page="5">PORTFOLIO</li>
        <li class="menu-list__item" data-page="6">CONTACT US</li>
      </ul>
    </div>
    <!-- OFFERS POPUP -->
    <div class="popup" data-popup="offers">
      <div class="popup__inner">
        <h2 class="popup__title mt-4">SERVICES</h2>
        <h2 class="popup__subtitle">Working in an Agile environment, we can create bespoke applications, platforms
          and products to meet the requirements of your business or new venture.</h2>
        <div class="popup__content popup__offers">
          <div class="popup__offer mb-5 pb-2">
            <h3 class="popup__offer-title mb-2">Software Development</h3>
            <ul>
              <li class="popup__offer-text">Web Development: PHP (Laravel), Node.js (Express), <br> Vue.js, React,
                Angular
              </li>
              <li class="popup__offer-text">Mobile Development: Flutter, React Native, Swift</li>
              <li class="popup__offer-text">Database Development: PL/SQL, MySQL, PostgreSQL</li>
              <li class="popup__offer-text">Infrastructure Deployment: AWS, DigitalOcean</li>
              <li class="popup__offer-text">Technical Architecture</li>
              <li class="popup__offer-text">Product Strategy</li>
            </ul>
          </div>
          <div class="popup__offer mb-5 pb-2">
            <h3 class="popup__offer-title mb-2">Dedicated Teams</h3>
            <ul>
              <li class="popup__offer-text">Full-Stack developers</li>
              <li class="popup__offer-text">Frontend & Backend developers</li>
              <li class="popup__offer-text">PL/SQL developers</li>
              <li class="popup__offer-text">Project managers</li>
            </ul>
          </div>
          <div class="popup__offer mb-5 pb-2">
            <h3 class="popup__offer-title mb-2">Consulting</h3>
            <ul>
              <li class="popup__offer-text">New Software Extensions & Modules</li>
              <li class="popup__offer-text">Tech Startups / Small business</li>
              <li class="popup__offer-text">Market & Product Research</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- TEAM POPUP -->
    <div class="popup" data-popup="team">
      <div class="popup__inner">
        <h2 class="popup__title mt-4">Our Consultants</h2>
        <h3 class="popup__subtitle">
          Leveraging as many or as few members of the team you need, we start working on your project: either as a
          technical advisor, an independent development team, or an extension of your internal team.
        </h3>

        <div class="slider">
          <div class="slider__arrow slider__arrow-left slider__arrow--disabled" data-follow="centered_y">
            <img loading="lazy" class="slider__arrow-icon"
                 src="./assets/images/icons/arrow.svg" alt="Arrow icon"/>
          </div>
          <div class="slider__arrow slider__arrow-right" data-follow="centered_y">
            <img loading="lazy" class="slider__arrow-icon"
                 src="./assets/images/icons/arrow.svg" alt="Arrow icon"/>
          </div>
          <div class="slider__inner" id="slider">
            <div class="slider__item">
              <img loading="lazy" class="slider__item-image"
                   src="./assets/images/persons/person1.jpg" alt="Team photo"/>
              <div class="slider__item-content">
                <h2 class="slider__item-name">Robert David</h2>
                <h2 class="slider__item-position">Engineering Manager</h2>
              </div>
            </div>
            <div class="slider__item">
              <img loading="lazy" class="slider__item-image"
                   src="./assets/images/persons/person2.jpg" alt="Team photo"/>
              <div class="slider__item-content">
                <h2 class="slider__item-name">Claudia Lupu</h2>
                <h2 class="slider__item-position">Database Engineer</h2>
              </div>
            </div>
            <div class="slider__item">
              <img loading="lazy" class="slider__item-image"
                   src="./assets/images/persons/person3.jpg" alt="Team photo"/>
              <div class="slider__item-content">
                <h2 class="slider__item-name">Ionut Oprea</h2>
                <h2 class="slider__item-position">Full-Stack Engineer</h2>
              </div>
            </div>
            <div class="slider__item">
              <img loading="lazy" class="slider__item-image"
                   src="./assets/images/persons/person4.jpg" alt="Team photo"/>
              <div class="slider__item-content">
                <h2 class="slider__item-name">Bogdan Moraru</h2>
                <h2 class="slider__item-position">Software Engineer</h2>
              </div>
            </div>
            <div class="slider__item">
              <img loading="lazy" class="slider__item-image"
                   src="./assets/images/persons/person5.jpg" alt="Team photo"/>
              <div class="slider__item-content">
                <h2 class="slider__item-name">Sergiu Lazar Angelescu</h2>
                <h2 class="slider__item-position">EU CS Product Manager</h2>
              </div>
            </div>
          </div>
        </div>

        <a href="https://www.linkedin.com/company/icdavid-technologies/people/" target="_blank">
          <button class="content-section__button button button--secondary">See All</button>
        </a>
      </div>
    </div>

    <div class="popup" data-popup="portfolio1">
      <div class="popup__inner">
        <h2 class="popup__title mt-4">Agendum</h2>
        <h2 class="popup__subtitle"><b>Description: </b>
          Agendum is a social networking app (web & mobile) that aims to create a new trend in the way people plan and share their activities.
        </h2>
        <p class="popup__subtitle"><b>Share and organize your virtual agenda.</b> <br><br>
          **Please note that our app currently operates on an invitation-only basis. To join, you'll need a valid invitation code.** <br><br>

          Some of the things you can do with Agendum: <br>
          * Let people know about your public calendar, shows, concerts, events you are attending. <br>
          * Let people know about the places you visited. Share photos and details (impression, prices or tips). <br>
          * Get people to react / buy tickets / join your agendums. <br>
          * See other people’s calendar, learn about new places & activities. <br>
        </p>
        <h2 class="popup__subtitle"><b>Product Owner & Technical Lead:</b> David Robert</h2>

        <a href="https://agendum.club/how-it-works/index.html" target="_blank">
          <button class="content-section__button button button--secondary">See Project</button>
        </a>
      </div>
    </div>

    <div class="popup" data-popup="portfolio2">
      <div class="popup__inner">
        <h2 class="popup__title mt-4">SpotYet</h2>
        <h2 class="popup__subtitle">
          <b>Description: </b>
          networking solution that will upscale the social connectedness and networking quality at your company or
          event. We offer two different products:
          <br>

          1) SpotBot for online socialising. We want make sure that your employees get proper water-cooler talks,
          especially during working-from-home and help you keep a cohesive team culture and interpersonal
          relationships. SpotBot is a Slack bot which reduces the distance between people working remotely and
          improves the groove of your virtual team by arranging small groups of people to meet for a short coffee
          break, suggesting icebreakers and many more configurable settings options.
          <br>
          2) SpotTalk for physical events and networking. Regardless of the size or type of event, SpotTalk
          enables participants to easily grow their network in a matter of seconds. Our solution works like a game
          by prompting players to find each other based on only their photos, while the matching is based on their
          common, previously selected interests. SpotTalk can be the perfect tool for integrating employees from
          different departments in big companies or as a networking booster at big events and conferences.
        </h2>
        <h2 class="popup__subtitle"><b>Project Manager:</b> Sergiu Lazar</h2>

        <a href="https://www.linkedin.com/company/spotyet/" target="_blank">
          <button class="content-section__button button button--secondary">See Project</button>
        </a>
      </div>
    </div>

    <div id="cookieNotice" class="popup popup-cookie popup--animated" data-popup="cookieNotice">
      <div class="popup-cookie__inner">
        <h2 class="popup__title mt-1 text-base mb-50">Cookie Consent</h2>
        <h2 class="popup__subtitle text-sm mb-2.5">
          This website uses cookies to enhance your browsing experience. By continuing to use our website, you consent
          to all cookies in accordance with our
          <button class="underline" id="button_cookie_policy">Cookie Policy</button>
          .
        </h2>

        <button class="content-section__button button button--secondary text-base p-1 m-0" id="button_cookies_consent">
          Accept
        </button>
      </div>
    </div>

    <div class="popup" data-popup="cookiePolicy">
      <div class="popup__inner">
        <h2 class="popup__title mt-4">Cookie Policy</h2>

        <div class="">
          <cookie-policy/>
        </div>
      </div>
    </div>
    <!-- ALL FIXED CONTENT (EG. HEADER, FOOTER) -->
    <!-- HEADER -->
    <header class="fixed-content-header">
      <div class="flex flex--center">
        <div class="burger" data-follow>
          <div class="burger__line"></div>
          <div class="burger__line"></div>
          <div class="burger__line"></div>
        </div>
        <!--          <div class="fixed-content-header__logo">ICDavid Technologies</div>-->
      </div>
      <button class="fixed-content-header__contact button button--primary" data-follow data-page="6">CONTACT</button>
    </header>
    <!-- PAGING -->
    <div class="fixed-content-paging">
      <div class="paging__page" data-page="0"></div>
      <div class="paging__page section--hidden" data-page="1"></div>
      <div class="paging__page section--hidden" data-page="2"></div>
      <div class="paging__page section--hidden" data-page="3"></div>
      <div class="paging__page section--hidden" data-page="4"></div>
      <div class="paging__page section--hidden" data-page="5"></div>
      <div class="paging__page section--hidden" data-page="6"></div>
    </div>

    <!-- FOOTER -->
    <div class="fixed-content-footer">
      <div class="social-links fixed-content-footer__copy">
        <!--        <div class="text-right">-->
        <!--            <button class="footer-copy__name">Follow Us</button>-->
        <!--        </div>-->

        <a class="social-links__anchor" href="https://www.linkedin.com/company/icdavid-technologies/"
           target="_blank">
          <img loading="lazy" class="social-links__image"
               src="./assets/images/socials/linkedin.svg" alt="LinkedIn logo" width="20"
               height="20">
        </a>

        <a class="social-links__anchor" href="https://www.crunchbase.com/organization/icdavid-technologies"
           target="_blank">
          <img loading="lazy" class="social-links__image"
               src="./assets/images/socials/crunchbase.svg" alt="Crunchbase logo" width="20"
               height="20">
        </a>
      </div>
      <div class="fixed-content-footer__copy">
        <div class="text-right">
          <button class="underline" id="button_cookie_policy_footer">Cookie Policy</button>
        </div>

        <span class="footer-copy__date"></span>
        <span class="footer-copy__name">ICDavid Technologies</span>
      </div>
    </div>
    <!-- MAIN CONTENT -->
    <div class="content">
      <div class="content-section" data-page="0">
        <div class="configuration__letters configuration__letters--hidden">
          <span class="configuration__letters-text">ICDavid Technologies</span>
        </div>
        <div class="scroll-letters">
          <span class="scroll-letters__letter" title="S">S</span>
          <span class="scroll-letters__letter" title="C">C</span>
          <span class="scroll-letters__letter" title="R">R</span>
          <span class="scroll-letters__letter" title="O">O</span>
          <span class="scroll-letters__letter" title="L">L</span>
          <span class="scroll-letters__letter" title="L">L</span>
        </div>
      </div>
      <div class="content-section section--hidden" data-page="1">
        <h1 class="content-section__title">ABOUT US</h1>
        <p class="content-section__text">
          ICDavid Technologies is a software development company with experience in outsourcing and remote
          staffing.
          <br>
          <br>
          Headquartered in Romania, but operating mostly in Europe and the US, we formed a group of top-tier
          developers with a passion for excellence.
          Together, as a team or individually as contractors, we help tech companies and startups achieve success.
        </p>
        <button class="content-section__button button button--secondary" id="button_services" data-follow
                data-page="2">OUR SERVICES
        </button>
      </div>

      <div class="content-section section--hidden" data-page="2">
        <h1 class="content-section__title">SERVICES</h1>
        <p class="content-section__text">

        </p>
        <div class="content-services">
          <div class="content-services__service">
            <img loading="lazy" class="content-service__image"
                 src="./assets/images/icons/custom-coding-svgrepo-com.svg"
                 alt="Coding icon"/>
            <span class="content-service__text">Software Development</span>
            <ul class="extended-services">
              <li class="extended-services__service">Web & Mobile Development</li>
              <li class="extended-services__service">Infrastructure Deployment</li>
              <li class="extended-services__service">Technical Architecture</li>
              <li class="extended-services__service">Product Strategy & Management</li>
            </ul>
          </div>
          <div class="content-services__service">
            <img loading="lazy" class="content-service__image"
                 src="./assets/images/icons/teamwork-svgrepo-com.svg" alt="Team icon"/>
            <span class="content-service__text">Dedicated Teams</span>
            <ul class="extended-services">
              <li class="extended-services__service">Full-Stack developers</li>
              <li class="extended-services__service">Frontend & Backend developers</li>
              <li class="extended-services__service">PL/SQL developers</li>
              <li class="extended-services__service">Project managers</li>
            </ul>
          </div>
          <div class="content-services__service">
            <img loading="lazy" class="content-service__image"
                 src="./assets/images/icons/consulting.svg" alt="Consulting icon"/>
            <span class="content-service__text">Consulting</span>
            <ul class="extended-services">
              <li class="extended-services__service">Growth</li>
              <li class="extended-services__service">Tech Startups / Small business</li>
              <li class="extended-services__service">Market & Product Research</li>
            </ul>
          </div>
        </div>
        <button class="content-section__button button button--secondary" id="button_offers">VIEW ALL SERVICES
        </button>
      </div>

      <div class="content-section section--hidden" data-page="3">
        <h1 class="content-section__title">OUR VALUES</h1>
        <p class="content-section__text">
          <b> Transparency </b><br>
          What we value most in the relationship we build with you is trust and transparency.
          Honesty and integrity aren't just nice words around here, they're part of our core identity.
        </p>

        <p class="content-section__text">
          <b> Communication </b><br>
          You’ll be the key decision maker through our collaboration.
          We believe in constant communication, though reporting and feedback.
          Our developers have business sense, so you speak directly with the people working on your project and
          nothing is lost in translation.
        </p>

        <p class="content-section__text">
          <b> Dedication </b><br>
          We are one team with ambitious goals and a shared vision, but we’ll only get there by working hard,
          together.
          Be rigorous, look for answers to questions in unexpected places and deliver the best solution for our
          client's needs.
        </p>
      </div>

      <div class="content-section section--hidden content-section--left-align" data-page="4">
        <div class="content-holder--left-align">
          <h1 class="content-section__title">TEAM</h1>
          <p class="content-section__text text-indent content-section__text-team">
            We supply better software development & talent by carefully understanding both client and engineer
            goals/expectations.
            <br>
            Our team is assembled mostly by experienced consultants, highly passionate individuals with a vision
            for your success, that can work together or individually to build your team and your product.
          </p>
          <button class="content-section__button button button--secondary" id="button_team">VIEW TEAM</button>
        </div>
        <div class="content-section__images">
          <div class="content-section__image-holder">
            <img loading="lazy" class="content-section__image"
                 src="./assets/images/persons/person1.jpg" alt="Team photo"/>
          </div>
          <div class="content-section__image-holder">
            <img loading="lazy" class="content-section__image"
                 src="./assets/images/persons/person2.jpg" alt="Team photo"/>
          </div>
          <div class="content-section__image-holder">
            <img loading="lazy" class="content-section__image"
                 src="./assets/images/persons/person3.jpg" alt="Team photo"/>
          </div>
          <div class="content-section__image-holder">
            <img loading="lazy" class="content-section__image"
                 src="./assets/images/persons/person4.jpg" alt="Team photo"/>
          </div>
        </div>
      </div>

      <div class="content-section section--hidden" data-page="5">
        <h1 class="content-section__title">PORTFOLIO</h1>
        <p class="content-section__text">
          We enjoy challenging ourselves creating our own in-house projects.
          <br>
          More about our work can be found on our
          <a class="content-section__link" href="https://www.linkedin.com/company/icdavid-technologies/people/"
             target="_blank" rel="noopener">
            <span class="button__text">LinkedIn Profiles</span>.
          </a>
        </p>

        <div class="content-portfolio__images flex">
          <div class="content-portfolio__image-holder">
            <button id="button_portfolio1"><span class="content-portfolio__name text-indent mb-1">View</span>
            </button>
            <img loading="lazy" class="content-portfolio__image"
                 src="./assets/images/portfolio/portfolio1.jpg" alt="Portfolio image"/>
          </div>

          <div class="content-portfolio__image-holder">
            <button id="button_portfolio2"><span class="content-portfolio__name text-indent mb-1">View</span>
            </button>
            <img loading="lazy" class="content-portfolio__image"
                 src="./assets/images/portfolio/portfolio2.jpg" alt="Portfolio image"/>
          </div>
        </div>
      </div>
      <div class="content-section section--hidden" data-page="6">
        <h1 class="content-section__title">CONTACT US</h1>
        <p class="content-section__text">
          We’re here to help and answer any question you might have.
          Please provide us with some information about your project and we'll be in touch shortly!
        </p>

        <div class="location-section grid grid-cols-2">
          <div class="location-item">
            <img loading="lazy" class="location-section__icon" src="./assets/images/icons/location.png"
                 alt="Location icon"/>

            <span class="location-section__text">1309, 447 Broadway, 2nd Floor, New York, US, 10013</span>
          </div>

          <div class="location-item">
            <img loading="lazy" class="location-section__icon" src="./assets/images/icons/location.png"
                 alt="Location icon"/>

            <span class="location-section__text">40 Dambovita, Bucharest, Romania, 060843</span>
          </div>
        </div>

        <div class="content-section__buttons-holder">
          <a class="content-section__button button button--icon" href="mailto:info@icdavid.tech">
            <img loading="lazy" class="button__image" src="./assets/images/icons/email.svg"
                 alt="Email icon"/>
            <span class="button__text">EMAIL US</span>
            <span class="button__invisible-email">info@icdavid.tech</span>
          </a>
          <!--            <a class="content-section__button button button&#45;&#45;icon" href="tel:+0011223344">-->
          <!--              <img loading="lazy" class="button__image" src="./assets/images/icons/phone.svg" alt="Phone icon"/>-->
          <!--              <span class="button__text">CALL US</span>-->
          <!--            </a>-->
        </div>
      </div>
    </div>
  </main>
</template>

<script>

const configuration = {
  SiteName: 'ICDavid Technologies',
  Use2DTextOver3D: false, // Change to true if you want 2D over 3D
  SiteNameSize: 0.3, // Between 0 and +
  NumberOfVerticalLines: 15,
  NumberOfDots: 5000,
  colors: {
    CanvasBackgroundColor: '#222222',
    LettersColor: '#F5C506',
    LinesColors: ['#967b08'],
    LowerLinesColors: ['#cfd0ca'],
    DotsColor: '#F5C506',
  },
};

// Import all needed dependencies.
import * as THREE from './plugins/three.module.min.js';
import TWEEN from './plugins/tween.js';
import UI from './plugins/ui.js';
import CookiePolicy from '@/components/CookiePolicy'

const windowHeightInRadians = 25;
let ui = null;
let camera, scene, renderer;
let sceneMovedAmount = 0, dragYDistance = 0, dragXDistance = 0;
let timeoutActive = false, shallMove = false, wait = false;

const mainGeometries = [];
let mainLettersMesh;
let touchStartPosition;

export default {
  name: 'App',
  components: {
    CookiePolicy
  },

  data() {
    return {
      font: require('@/assets/fonts/Roboto-Black-3d.json'),
    };
  },

  mounted() {
    window.addEventListener('load', () => {
      const uiWrapper = document.querySelector('.ui-wrapper');
      ui = new UI(this.uiCallback, uiWrapper);

      uiWrapper.classList.remove('page-not-loaded');
      // Loading animation lasts for 3s;
      setTimeout(() => {
        this.init();
        this.animate();
      }, 1000);
    });
  },

  methods: {
    init() {
      camera = new THREE.PerspectiveCamera(55,
          window.innerWidth / window.innerHeight, 2, 20000);
      camera.position.z = 20;

      scene = new THREE.Scene();
      scene.background = new THREE.Color(configuration.colors.CanvasBackgroundColor);
      const near = 10;
      const far = 150;
      scene.fog = new THREE.Fog(configuration.colors.CanvasBackgroundColor, near,
          far);

      // Load main letters and generate random lines.
      if (!configuration.Use2DTextOver3D) {
        this.loadMainLetters();
      } else {
        this.loadMain2DLetters();
      }
      for (let index = 0; index < configuration.NumberOfVerticalLines; index++) {
        this.generateRandomObject(1, [[0.2, 2, 4, 5], [0.1, 0.2]],
            configuration.colors.LinesColors);
        // Generate few random objects per page.
        this.generateRandomObject(-windowHeightInRadians * index / 3, [[2, 4], [0.05]],
            configuration.colors.LowerLinesColors);
      }

      renderer = new THREE.WebGLRenderer();
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      document.body.appendChild(renderer.domElement);

      // Add listeners.
      window.addEventListener('resize', this.windowResize, false);
      window.addEventListener('wheel', this.windowWheelOrTouch, false);
      window.addEventListener('touchstart', e => {
        touchStartPosition = e.touches[0].pageY;
      }, false);
      window.addEventListener('touchmove', this.windowWheelOrTouch, false);
      // if (!isMobile())
      window.addEventListener('mousedown', this.mouseDown, false);
      window.addEventListener('mouseup', this.mouseUp, false);
      window.addEventListener('mousemove', this.mouseMove, false);
    },

    animate(time) {
      requestAnimationFrame(this.animate);
      TWEEN.update();
      this.render(time);
    },

    render(time) {
      time = time / 1000;

      if (mainLettersMesh) mainLettersMesh.material.uniforms.time.value = time;
      // Move geometries left and right.
      mainGeometries.forEach((geometry, index) => {
        geometry.scale.x = Math.sin(time / 2 + index * 3) * 0.5 + 0.5;
      });

      renderer.render(scene, camera);
    },

// **** HELPER FUNCTIONS **** //

// Generate main geometries by random width, height, color and position.
    generateRandomObject(
        verticalPosition, availableSizes, availableColors) {
      const randomIntFromInterval = (min, max) => Math.floor(
          Math.random() * (max - min + 1) + min);

      const randomWidth = availableSizes[0][randomIntFromInterval(0,
          availableSizes[0].length - 1)];
      const randomHeight = availableSizes[1][randomIntFromInterval(0,
          availableSizes[1].length - 1)];
      const randomColor = availableColors[randomIntFromInterval(0,
          availableColors.length - 1)];

      const geometry = new THREE.PlaneBufferGeometry(randomWidth, randomHeight, 1);
      geometry.applyMatrix4(
          new THREE.Matrix4().makeTranslation(-geometry.parameters.width / 2, 0, 0));
      const material = new THREE.MeshBasicMaterial(
          {color: randomColor, side: THREE.FrontSide});
      const mesh = new THREE.Mesh(geometry, material);

      mesh.position.x = randomIntFromInterval(-10, 10);
      mesh.position.y = verticalPosition + randomIntFromInterval(-10, 10);
      mesh.position.z = randomIntFromInterval(-10, 10);

      scene.add(mesh);
      mainGeometries.push(mesh);
    },

    uiCallback() {
      return {
        onPagingClick: (pagingIndex) => {
          if (sceneMovedAmount > sceneMovedAmount) ui.ui_moveScene('down');
          else ui.ui_moveScene('up');

          sceneMovedAmount = pagingIndex;
          this.moveScene();
        },
        getCurrentPage: () => {
          return sceneMovedAmount;
        },
        blockSceneScrolling: (active) => {
          active ? timeoutActive = true : timeoutActive = false;
        },
      };
    },

    async loadMainLetters() {
      const fontLoader = new THREE.FontLoader();

      fontLoader.load('./fonts/Roboto-Black-3d.json', font => {
        let textGeometry = new THREE.TextGeometry(configuration.SiteName,
            {font: font, size: 6, height: 3, curveSegments: 5});
        textGeometry.center();

        textGeometry.scale(configuration.SiteNameSize, configuration.SiteNameSize + 0.1,
            configuration.SiteNameSize - 0.2);

        const textMaterial = new THREE.ShaderMaterial({
          uniforms: {
            time: {value: 0},
            color: {
              type: 'vec3',
              value: new THREE.Color(configuration.colors.LettersColor),
            },
          },
          vertexShader: this.vertexShader(),
          fragmentShader: this.fragmentShader(),
          side: THREE.DoubleSide,
          wireframe: true,
        });
        mainLettersMesh = new THREE.Mesh(textGeometry, textMaterial);
        scene.add(mainLettersMesh);

        let vertices = [];

        for (let i = 0; i < configuration.NumberOfDots; i++) {
          let x = Math.random() * 200 - 100;
          let y = Math.random() * 200 - 100;
          let z = Math.random() * 200 - 100;

          vertices.push(x, y, z);
        }

        const bufferGeometry = new THREE.BufferGeometry();
        bufferGeometry.setAttribute('position',
            new THREE.Float32BufferAttribute(vertices, 3));
        const pointSprite = new THREE.TextureLoader().load(
            './img/pointimg.png');
        const pointsMaterial = new THREE.PointsMaterial({
          color: configuration.colors.DotsColor,
          size: 0.5,
          map: pointSprite,
          transparent: true,
          alphaTest: 0.5,
        });
        const points = new THREE.Points(bufferGeometry, pointsMaterial);
        scene.add(points);

        this.windowResize();
      });
    },

    vertexShader() {
      return `
  varying vec2 vUv;
  uniform float time;

  vec3 mod289(vec3 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
  }

  vec4 mod289(vec4 x) {
    return x - floor(x * (1.0 / 289.0)) * 289.0;
  }

  vec4 permute(vec4 x) {
       return mod289(((x*34.0)+1.0)*x);
  }

  vec4 taylorInvSqrt(vec4 r)
  {
    return 1.79284291400159 - 0.85373472095314 * r;
  }

  float snoise(vec3 v) {
    const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
    const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

    // First corner
    vec3 i  = floor(v + dot(v, C.yyy) );
    vec3 x0 =   v - i + dot(i, C.xxx) ;

    // Other corners
    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 i1 = min( g.xyz, l.zxy );
    vec3 i2 = max( g.xyz, l.zxy );

    //   x0 = x0 - 0.0 + 0.0 * C.xxx;
    //   x1 = x0 - i1  + 1.0 * C.xxx;
    //   x2 = x0 - i2  + 2.0 * C.xxx;
    //   x3 = x0 - 1.0 + 3.0 * C.xxx;
    vec3 x1 = x0 - i1 + C.xxx;
    vec3 x2 = x0 - i2 + C.yyy; // 2.0*C.x = 1/3 = C.y
    vec3 x3 = x0 - D.yyy;      // -1.0+3.0*C.x = -0.5 = -D.y

    // Permutations
    i = mod289(i);
    vec4 p = permute( permute( permute(
               i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
             + i.y + vec4(0.0, i1.y, i2.y, 1.0 ))
             + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

    // Gradients: 7x7 points over a square, mapped onto an octahedron.
    // The ring size 17*17 = 289 is close to a multiple of 49 (49*6 = 294)
    float n_ = 0.142857142857; // 1.0/7.0
    vec3  ns = n_ * D.wyz - D.xzx;

    vec4 j = p - 49.0 * floor(p * ns.z * ns.z);  //  mod(p,7*7)

    vec4 x_ = floor(j * ns.z);
    vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

    vec4 x = x_ *ns.x + ns.yyyy;
    vec4 y = y_ *ns.x + ns.yyyy;
    vec4 h = 1.0 - abs(x) - abs(y);

    vec4 b0 = vec4( x.xy, y.xy );
    vec4 b1 = vec4( x.zw, y.zw );

    //vec4 s0 = vec4(lessThan(b0,0.0))*2.0 - 1.0;
    //vec4 s1 = vec4(lessThan(b1,0.0))*2.0 - 1.0;
    vec4 s0 = floor(b0)*2.0 + 1.0;
    vec4 s1 = floor(b1)*2.0 + 1.0;
    vec4 sh = -step(h, vec4(0.0));

    vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
    vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

    vec3 p0 = vec3(a0.xy,h.x);
    vec3 p1 = vec3(a0.zw,h.y);
    vec3 p2 = vec3(a1.xy,h.z);
    vec3 p3 = vec3(a1.zw,h.w);

    // Normalise gradients
    vec4 norm = taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;

    // Mix final noise value
    vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
    m = m * m;
    return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1),
                                  dot(p2,x2), dot(p3,x3) ) );
  }

  void main() {
    vUv = uv;

    vec3 pos = position;
    float noiseFreq = 3.5;
    float noiseAmp = 0.15;
    vec3 noisePos = vec3(pos.x * noiseFreq + time, pos.y, pos.z);
    pos.x += snoise(noisePos) * noiseAmp;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.);
  }
  `;
    },

    fragmentShader() {
      return `
  uniform vec3 color;
  void main() {
    gl_FragColor = vec4(color, 1.0 );
  }
  `;
    },

    loadMain2DLetters() {
      const configurationLetters = document.querySelector('.configuration__letters');
      configurationLetters.classList.remove('configuration__letters--hidden');
    },

    isMobile() {
      try {
        document.createEvent('touchEvent');
        return true;
      } catch (err) {
        return false;
      }
    },

// **** EVENT FUNCTIONS **** //

    moveScene() {
      new TWEEN.Tween(scene.position).to({
        x: scene.position.x,
        y: sceneMovedAmount * windowHeightInRadians,
        z: scene.position.z,
      }, 1000).easing(TWEEN.Easing.Quartic.InOut).start();
    },

    windowResize() {
      if (mainLettersMesh) {
        const scaleAmmount = Math.min(window.innerWidth / 1100, 1);
        mainLettersMesh.scale.x = scaleAmmount;
        mainLettersMesh.scale.y = scaleAmmount;
      }
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    },

    windowWheelOrTouch(e) {
      // Limit scrolling to scroll only once in N milliseconds.
      if (timeoutActive) return;
      timeoutActive = true;
      setTimeout(() => {
        timeoutActive = false;
      }, 1500);

      if (e.deltaY > 0 || (e.touches && e.touches[0].pageY < touchStartPosition)) {
        if (sceneMovedAmount === 6) return;
        sceneMovedAmount++;
        sceneMovedAmount = Math.min(sceneMovedAmount, 6);
        this.moveScene();
        ui.ui_moveScene('down');
        return;
      }

      if (sceneMovedAmount === 0) return;
      sceneMovedAmount--;
      sceneMovedAmount = Math.max(sceneMovedAmount, 0);
      this.moveScene();
      ui.ui_moveScene('up');
    },

    mouseDown(e) {
      shallMove = true;

      if (!dragXDistance) {
        dragXDistance = e.clientX;
      }

      if (!dragYDistance) {
        dragYDistance = e.clientY;
      }
    },

    mouseUp(e) {
      shallMove = false;

      dragXDistance = dragYDistance = 0;
    },

    mouseMove(e) {
      if (!shallMove) return;

      if (!wait) {
        // fire the event
        this.moveCamera(e);
        // stop any further events
        wait = true;
        // after a fraction of a second, allow events again
        setTimeout(() => {
          wait = false;
        }, 10);
      }
    },

    moveCamera(e) {
      ui.ui_moveEvent(e, configuration.Use2DTextOver3D);
      if (sceneMovedAmount > 0) return;

      const CameraXPosition = (e.clientX - dragXDistance) / 2000;
      const CameraYPosition = (e.clientY - dragYDistance) / 2000;

      camera.position.x -= CameraXPosition;
      camera.position.y += CameraYPosition;

      if (camera.position.x > 0) {
        camera.position.x = Math.min(camera.position.x, 30);
      } else {
        camera.position.x = Math.max(camera.position.x, -30);
      }

      if (camera.position.y > 0) {
        camera.position.y = Math.min(camera.position.y, 30);
      } else {
        camera.position.y = Math.max(camera.position.y, -30);
      }

      camera.lookAt(scene.position);
    },
  },
};
</script>

<style lang="scss">
</style>
